import React from 'react';
import {NavLink, useNavigate, useParams} from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import KeyIcon from '@mui/icons-material/Key';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SaveIcon from '@mui/icons-material/Save';
import TranslateIcon from '@mui/icons-material/Translate';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {AxiosError, AxiosResponse} from 'axios';

import {
  apiCall,
  areRevisionsSame,
  hideLoader,
  showLoader,
  stringContent,
} from '../../utils';

import {ActiveKeyDialog} from './ActiveKeyDialog';
import {CommitDialog} from './CommitDialog';
import {ImportKeysDialog} from './ImportKeysDialog';
import {KeyTranslationRow} from './KeyTranslationRow';
import {NewLangDialog} from './NewLangDialog';
import {PullDialog} from './PullDialog';
import {TranslationProgressDialog} from './TranslationProgressDialog';

import {
  CommitRequestDTO,
  ExpandedKeys,
  KeyTranslation,
  ProjectRevision,
  ProjectTranslation,
  ProjectWithRevisionDTO,
  TranslationRequestDTO,
  TranslationResponseDTO,
  TranslationSource,
} from '@bumpy/i18n_common';

type DialogType =
  | 'NONE'
  | 'NEW_LANG'
  | 'PULL'
  | 'COMMIT'
  | 'TRANSLATION_PROGRESS'
  | 'IMPORT_KEYS';

export const ProjectRevisionForm: React.FC = () => {
  const navigate = useNavigate();
  const {projectUuid, revisionUuid} = useParams();

  const [dto, setDto] = React.useState<ProjectWithRevisionDTO | undefined>(
    undefined,
  );
  const originalDto = React.useRef<ProjectWithRevisionDTO>();
  const translationRunning = React.useRef(false);
  const [translationMessage, setTranslationMessage] = React.useState('');
  const [lang, setLang] = React.useState('en');
  const [search, setSearch] = React.useState('');
  const [activeKey, setActiveKey] = React.useState<string | undefined>();
  const [dialogType, setDialogType] = React.useState<DialogType>('NONE');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [checkLang, setCheckLang] = React.useState('uk');
  const [expandedKeysOnly, setExpandedKeysOnly] = React.useState(false);
  const [autoSave, setAutoSave] = React.useState(true);
  const [runValidation, setRunValidation] = React.useState(true);
  let translationIndex = 0;
  if (
    dto &&
    dto.revision.translationIndex &&
    dto.revision.translationIndex[lang]
  ) {
    translationIndex = dto.revision.translationIndex[lang];
  }

  function refresh(): void {
    apiCall({
      method: 'GET',
      url: `/projects/${projectUuid}/revisions/${revisionUuid}`,
      secure: true,
    }).then((response) => {
      const dto = response.data as ProjectWithRevisionDTO;
      setDto(dto);
      setLang(dto.project.mainLang);
      originalDto.current = JSON.parse(JSON.stringify(dto));
    });
  }

  React.useEffect(refresh, [projectUuid, revisionUuid]);

  function onSaveClick(): Promise<void> {
    showLoader('Saving...');
    let url = `/projects/${projectUuid}/revisions/${revisionUuid}`;
    if (!runValidation) {
      url += '?auto_save=1';
    }
    return apiCall({
      method: 'PUT',
      url,
      data: dto!.revision,
      secure: true,
    })
      .then((response) => {
        const revision = response.data.revision as ProjectRevision;
        if (revision.uuid === dto!.revision.uuid) {
          const newDto = {...dto!};
          newDto.revision = revision;
          originalDto.current = JSON.parse(JSON.stringify(newDto));
          setDto(newDto);
        } else {
          alert('Revision has conflict, created new one');
          navigate(`/projects/${projectUuid}/revisions/${revision.uuid}`);
        }
      })
      .catch((e) => {
        if (e instanceof AxiosError && e.response) {
          const {data} = e.response;
          if (data.message) {
            alert(data.message);
            return;
          }
        }
        alert('Unknown error during saving');
      })
      .finally(hideLoader);
  }

  function setTranslation(key: string, translation: string | object): void {
    const newDto = {...dto!};
    newDto.revision.translations[lang][key] = translation;
    setDto(newDto);
  }

  function setKeyTranslations(
    key: string,
    translations: KeyTranslation,
  ): boolean {
    const newDto = {...dto!};
    const langs = Object.keys(translations);
    for (const lang of langs) {
      newDto.revision.translations[lang][key] = translations[lang];
    }
    const indexActive = newDto.revision.keys.indexOf(activeKey!);
    const indexKey = newDto.revision.keys.indexOf(key);
    if (key !== activeKey) {
      if (indexKey !== -1) {
        alert('New key name exists');
        return false;
      }
      for (const lang of langs) {
        delete newDto.revision.translations[lang][activeKey!];
      }
    }
    if (indexActive === -1) {
      newDto.revision.keys.push(key);
    } else {
      newDto.revision.keys[indexActive] = key;
    }

    setDto(newDto);
    setActiveKey(undefined);
    return true;
  }

  function deleteActiveKey(): void {
    const newDto = {...dto!};
    const langs = Object.keys(newDto.revision.translations);
    for (const lang of langs) {
      delete newDto.revision.translations[lang][activeKey!];
    }
    const index = newDto.revision.keys.indexOf(activeKey!);
    newDto.revision.keys.splice(index, 1);
    setDto(newDto);
    setActiveKey(undefined);
  }

  function addNewLang(newLang: string): void {
    const newDto = {...dto!};
    const translation: ProjectTranslation = {};
    for (const key of dto!.revision.keys) {
      translation[key] = '';
    }
    newDto.revision.translations[newLang] = translation;
    setDto(newDto);
    setLang(newLang);
    setDialogType('NONE');
  }

  function pull(branch: string): void {
    showLoader('Pulling...');
    apiCall({
      method: 'POST',
      url: `/projects/${projectUuid}/revisions/${revisionUuid}/pull/${branch}`,
      secure: true,
    })
      .then((response) => {
        const revision = response.data.revision as ProjectRevision;
        const newDto = {...dto!};
        newDto.revision = revision;
        setDto(newDto);
        setDialogType('NONE');
      })
      .catch((e) => {
        if (e instanceof AxiosError && e.response) {
          alert('Error during pull "' + e.response.data.err + '"');
          return;
        }
        alert('Unknown error during pull');
      })
      .finally(hideLoader);
  }

  function commit(branch: string, message: string): void {
    const data: CommitRequestDTO = {
      branch,
      message,
    };

    showLoader('Commiting...');
    apiCall({
      method: 'POST',
      url: `/projects/${projectUuid}/revisions/${revisionUuid}/commit`,
      data,
      secure: true,
    })
      .then(() => {
        const newDto = {...dto!};
        newDto.revision.committed = true;
        setDto(newDto);
        setDialogType('NONE');
      })
      .catch((e) => {
        if (e instanceof AxiosError && e.response) {
          alert('Error during commit "' + e.response.data.err + '"');
          return;
        }
        alert('Unknown error during commit');
      })
      .finally(hideLoader);
  }

  function renderActionButton(): React.ReactNode {
    if (originalDto.current && dto) {
      const same = areRevisionsSame(originalDto.current.revision, dto.revision);

      if (dto.project.git && same && !dto.revision.committed) {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDialogType('COMMIT');
            }}
            startIcon={<UploadIcon />}
          >
            Commit
          </Button>
        );
      }

      return (
        <Stack>
          <Button
            disabled={same}
            variant="contained"
            color="primary"
            onClick={onSaveClick}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          {dto.project.validationRunScript && (
            <FormControlLabel
              control={<Checkbox defaultChecked={runValidation} />}
              value={runValidation}
              label="Validate"
              onChange={(_, value) => {
                setRunValidation(value);
              }}
            />
          )}
        </Stack>
      );
    }
  }

  let keys: string[] | undefined;
  let translation: ProjectTranslation | undefined;
  if (dto) {
    translation = dto.revision.translations[lang];
    // keys = dto.revision.keys;
    keys = Object.keys(translation);
    if (search.length > 0) {
      try {
        const regex = new RegExp(search);
        keys = keys.filter((key) => {
          if (regex.test(key)) {
            return true;
          }
          const v = translation![key];
          if (typeof v !== 'string') {
            return false;
          }
          return regex.test(v);
        });
      } catch {
        const s = search.trim().toLowerCase();
        keys = keys.filter((key) => {
          if (key.includes(s)) {
            return true;
          }
          const v = translation![key];
          if (typeof v !== 'string') {
            return false;
          }
          return v.toLowerCase().includes(s);
        });
      }
    }
    if (expandedKeysOnly) {
      keys = keys.filter((key) => dto.revision.expandedKeys[key]);
    }
  }
  const searchTranslation = search.length > 0 && keys!.length > 0;

  async function onTranslateClick(): Promise<void> {
    const data: TranslationRequestDTO = {
      projectUuid: projectUuid!,
      checkLang,
      fromLang: dto!.project.mainLang,
      content: {},
      toLangs: [],
    };
    let keysForTranslation: string[];
    if (searchTranslation) {
      keysForTranslation = keys!;
      data.toLangs = Object.keys(dto!.revision.translations).filter(
        (lang) => lang !== dto!.project.mainLang,
      );
    } else {
      keysForTranslation = dto!.revision.keys;
      data.toLangs.push(lang);
    }
    for (const key of keysForTranslation) {
      const content = dto!.revision.translations[dto!.project.mainLang][key];
      if (
        !content ||
        (typeof content === 'string' && content.trim().length < 1)
      ) {
        alert('Missed original text for ' + key);
        return;
      }
    }
    setDialogType('TRANSLATION_PROGRESS');
    translationRunning.current = true;
    try {
      const page = 20;
      for (
        let i = translationIndex;
        i < keysForTranslation.length && translationRunning.current;
        i += page
      ) {
        data.content = {};
        const max = Math.min(keysForTranslation.length, i + page);
        for (let j = i; j < max; ++j) {
          const key = keysForTranslation[j];
          data.content[key] =
            dto!.revision.translations[dto!.project.mainLang][key];
        }
        const progress = Math.ceil((i / keysForTranslation.length) * 100);
        setTranslationMessage(`Translating ${progress}% ...`);
        let response: AxiosResponse;
        try {
          response = await apiCall({
            method: 'POST',
            url: '/translations',
            secure: true,
            data,
          });
        } catch {
          alert('Some error during translation');
          continue;
        }
        const newExpandedKeys: ExpandedKeys = {...dto!.revision.expandedKeys};
        const newDto = {...dto!};
        const revision = {
          ...dto!.revision,
        };
        for (const translatedLang of Object.keys(response.data)) {
          const translatedKeys = (response.data as TranslationResponseDTO)[
            translatedLang
          ];
          for (const key of Object.keys(translatedKeys)) {
            const translation = translatedKeys[key];
            const sources = Object.keys(translation) as TranslationSource[];
            let matches = 1;
            let checks = 1;
            const picked = translation[sources[0]].tr[0];
            let first = translation[sources[0]].check;
            for (let j = 1; j < sources.length; ++j) {
              if (picked === translation[sources[j]].tr[0]) {
                matches++;
              }
              if (first && first === translation[sources[j]].check) {
                checks++;
              }
            }
            if (matches === sources.length) {
              newExpandedKeys[key] = false;
              newDto.revision.translations[translatedLang][key] = picked;
            } else if (checks === sources.length) {
              if (
                translation.deepl &&
                typeof translation.deepl.tr[0] === 'string'
              ) {
                first = translation.deepl.tr[0];
              }

              newExpandedKeys[key] = false;
              newDto.revision.translations[translatedLang][key] = first!;
            } else {
              newExpandedKeys[key] = true;
            }
          }
          revision.translationDto = {
            ...dto!.revision!.translationDto,
            [translatedLang]: {
              ...(dto!.revision!.translationDto
                ? dto!.revision!.translationDto[translatedLang]
                : {}),
              ...translatedKeys,
            },
          };
          if (!revision.translationIndex) {
            revision.translationIndex = {};
          }
          revision.translationIndex[translatedLang] = i;
        }
        revision.expandedKeys = newExpandedKeys;

        setDto({
          ...dto!,
          revision,
        });
        if (autoSave && i >= 5 && i % 5 === 0) {
          await apiCall({
            method: 'PUT',
            url: `/projects/${projectUuid}/revisions/${revisionUuid}?auto_save=1`,
            data: revision,
            secure: true,
          });
        }
      }
    } finally {
      setDialogType('NONE');
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Breadcrumbs aria-label="breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink to="/">Projects</NavLink>
            <NavLink to={`/${projectUuid}`}>{dto?.project.name ?? ''}</NavLink>
            <Typography color="secondary">
              {dto?.revision.name ?? ''}
            </Typography>
          </Breadcrumbs>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={1}>
        {dto && (
          <Select
            fullWidth
            value={lang}
            variant="standard"
            onChange={(event) => {
              const {value} = event.target;
              if (value === 'new') {
                setDialogType('NEW_LANG');
              } else {
                setLang(value);
              }
            }}
          >
            <MenuItem value="new">
              <AddIcon />
              New
            </MenuItem>
            {Object.keys(dto.revision.translations).map((lang) => (
              <MenuItem key={lang} value={lang}>
                {lang}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
      {dto?.project.git && (
        <Grid item xs={1.5}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDialogType('PULL');
            }}
            startIcon={<DownloadIcon />}
          >
            Pull
          </Button>
        </Grid>
      )}
      <Grid item xs={2.5}>
        {dto?.project.git && dto?.project.validationSetupProjectScript && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              showLoader('Re-init validation...');
              apiCall({
                method: 'POST',
                url: `/projects/${projectUuid}/revisions/${revisionUuid}/validation/setup`,
                secure: true,
              }).finally(hideLoader);
            }}
            startIcon={<PublishedWithChangesIcon />}
          >
            Reinit validation
          </Button>
        )}
      </Grid>
      <Grid item xs={1.5}>
        {renderActionButton()}
      </Grid>
      <Grid item xs={7}>
        <Stack direction="row">
          <TextField
            label="Search"
            margin="none"
            size="small"
            sx={{width: 350}}
            value={search}
            onChange={(event) => {
              setPage(0);
              setSearch(event.target.value);
            }}
          />
          <FormControlLabel
            control={<Checkbox defaultChecked={expandedKeysOnly} />}
            value={expandedKeysOnly}
            label="Expanded keys only"
            onChange={(_, value) => {
              setExpandedKeysOnly(value);
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={5}>
        <Stack alignItems="flex-end">
          <TablePagination
            rowsPerPageOptions={[50, 100, 200]}
            variant="body"
            count={keys?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, page) => {
              setPage(page);
            }}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              setPage(0);
              setRowsPerPage(parseInt(event.target.value, 10));
            }}
          />
        </Stack>
      </Grid>
      {dto && (
        <>
          <Grid item xs={12}>
            <TableContainer sx={{maxHeight: 700}}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell width={400}>
                      Key
                      <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={() => {
                          setActiveKey('new_key');
                        }}
                        startIcon={<KeyIcon />}
                      >
                        New
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={() => {
                          setDialogType('IMPORT_KEYS');
                        }}
                        startIcon={<DownloadIcon />}
                      >
                        Import
                      </Button>
                    </TableCell>
                    <TableCell width={400}>
                      Translation
                      {(lang !== dto.project.mainLang || searchTranslation) && (
                        <>
                          <TextField
                            value={translationIndex.toString()}
                            type="number"
                            variant="standard"
                            sx={{width: 60, marginLeft: 1}}
                            onChange={(event) => {
                              const value = Math.min(
                                Math.max(Number(event.target.value), 0),
                                dto.revision.keys.length - 1,
                              );

                              setDto({
                                ...dto,
                                revision: {
                                  ...dto!.revision,
                                  translationIndex: {
                                    ...dto!.revision!.translationIndex,
                                    [lang]: value,
                                  },
                                },
                              });
                            }}
                          />
                          <IconButton onClick={onTranslateClick}>
                            <TranslateIcon />
                          </IconButton>
                          <Select
                            value={checkLang}
                            variant="standard"
                            onChange={(event) => {
                              setCheckLang(event.target.value);
                            }}
                          >
                            {Object.keys(dto.revision.translations).map(
                              (lang) => (
                                <MenuItem key={lang} value={lang}>
                                  {lang}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderKeys(
                    dto,
                    keys!,
                    translation!,
                    page,
                    rowsPerPage,
                    lang,
                    search,
                    setActiveKey,
                    setDto,
                    setTranslation,
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {activeKey && (
            <ActiveKeyDialog
              activeKey={activeKey}
              checkLang={checkLang}
              revision={dto.revision}
              project={dto.project}
              onClose={() => setActiveKey(undefined)}
              onDeleteKey={deleteActiveKey}
              onSave={setKeyTranslations}
            />
          )}
          <NewLangDialog
            open={dialogType === 'NEW_LANG'}
            onClose={() => setDialogType('NONE')}
            onNewLangCreate={addNewLang}
          />
          <PullDialog
            branch={dto?.revision.branch}
            open={dialogType === 'PULL'}
            onClose={() => setDialogType('NONE')}
            onPull={pull}
          />
          <CommitDialog
            branch={dto?.revision.branch}
            open={dialogType === 'COMMIT'}
            onClose={() => setDialogType('NONE')}
            onCommit={commit}
          />
          <TranslationProgressDialog
            autoSave={autoSave}
            message={translationMessage}
            open={dialogType === 'TRANSLATION_PROGRESS'}
            onAutoSave={setAutoSave}
            onPause={() => {
              setDialogType('NONE');
              translationRunning.current = false;
            }}
          />
          <ImportKeysDialog
            open={dialogType === 'IMPORT_KEYS'}
            onClose={() => {
              setDialogType('NONE');
            }}
            onKeysImported={(translation: ProjectTranslation) => {
              const keys = Object.keys(translation);
              if (keys.length === 0) {
                alert('Nothing to import');
                return;
              }
              const currentTranslation = dto!.revision.translations[lang];
              for (const key of keys) {
                if (currentTranslation[key]) {
                  alert('Key ' + key + ' already exists');
                  return;
                }
                const value = translation[key];
                console.log('TypeofValue: ' + typeof value);
                if (typeof value === 'string') {
                  if (value.trim().length === 0) {
                    alert('Empty string value for key ' + key);
                    return;
                  }
                } else if (typeof value !== 'object' && !Array.isArray(value)) {
                  alert('Unsupported value for key ' + key);
                  return;
                }
              }
              for (const key of keys) {
                if (!dto!.revision.keys.includes(key)) {
                  dto!.revision.keys.push(key);
                }
                currentTranslation[key] = translation[key];
              }
              setDialogType('NONE');
            }}
          />
        </>
      )}
    </Grid>
  );
};

function renderKeys(
  dto: ProjectWithRevisionDTO,
  keys: string[],
  translation: ProjectTranslation,
  page: number,
  rowsPerPage: number,
  lang: string,
  search: string,
  setActiveKey: (key: string) => void,
  setDto: (dto: ProjectWithRevisionDTO) => void,
  setTranslation: (key: string, translation: string | object) => void,
): React.ReactNode {
  const translationLangDto = dto.revision.translationDto
    ? dto.revision.translationDto[lang]
    : undefined;
  const start = rowsPerPage * page;
  let end = start + rowsPerPage;
  if (end > keys.length) {
    end = keys.length - 1;
  }

  const views: React.ReactNode[] = [];
  for (let i = start; i <= end; ++i) {
    const key = keys[i];

    views.push(
      <KeyTranslationRow
        expandedKeys={dto.revision.expandedKeys}
        key={key}
        keyName={key}
        translation={stringContent(translation[key])}
        translations={dto.revision.translations}
        translationKeyDto={
          translationLangDto ? translationLangDto[key] : undefined
        }
        onKeyClick={setActiveKey}
        setExpandedKeys={(expandedKeys: ExpandedKeys) => {
          setDto({
            ...dto,
            revision: {
              ...dto.revision,
              expandedKeys,
            },
          });
        }}
        onTranslationChange={setTranslation}
      />,
    );
  }

  return views;
}
